.fr-callout span {
	color: var(--border-default-blue-france);
}

.fr-callout--clear {
	background-color: white;
}

.fr-callout div {
	margin-top: 1rem;
}